import storage from './storage';

export type AuthType = 'authorize_calendar' | 'register';

const GOOGLE_SCOPES = {
    CALENDAR: 'https://www.googleapis.com/auth/calendar.readonly',
    CALENDAR_EVENTS: 'https://www.googleapis.com/auth/calendar.events.readonly',
    EMAIL: 'https://www.googleapis.com/auth/userinfo.email',
    PROFILE: 'https://www.googleapis.com/auth/userinfo.profile',
};

const ENV_OAUTH_URL = process.env.REACT_APP_OAUTH_URL || '';
export const GOOGLE_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_ID || '';
export const GOOGLE_REDIRECT: string = ENV_OAUTH_URL
    ? `${ENV_OAUTH_URL}/finish-oauth`
    : `${window.location.origin}/finish-oauth`;

function createState(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 32; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

export function generateGoogleAuthUrl(
    authType: AuthType,
    token?: string | null,
    email?: string,
    passedState?: string
): string {
    const sharedLinkToken = new URL(window.location.href).searchParams.get('shared_link_token');
    // authType Authorize or register
    const state = passedState || createState();
    storage.setItem(state, {
        activateToken: token,
        authType,
        sharedLinkToken,
    });

    let scope = '';
    if (authType === 'register') {
        scope = `${GOOGLE_SCOPES.EMAIL} ${GOOGLE_SCOPES.PROFILE}`;
    } else if (authType === 'authorize_calendar') {
        scope = `${GOOGLE_SCOPES.CALENDAR} ${GOOGLE_SCOPES.CALENDAR_EVENTS}`;
    } else {
        throw new Error(`Unknown auth type: ${authType}`);
    }

    if (ENV_OAUTH_URL) {
        return `${ENV_OAUTH_URL}/auth?state=${btoa(state)}&auth_type=${authType}&redirect_to_env=${encodeURIComponent(
            window.location.origin
        )}`;
    }

    let approvalPrompt = '';
    if (authType === 'authorize_calendar') {
        approvalPrompt = 'approval_prompt=force&';
    }

    return `https://accounts.google.com/o/oauth2/auth?include_granted_scopes=true&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT}&response_type=code&scope=${scope}&access_type=offline&${approvalPrompt}state=${btoa(
        state
    )}${email ? `&login_hint=${encodeURI(email)}` : ''}`;
}
