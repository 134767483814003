import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface VideoState {
    inProgress: boolean;
}

const initialState: VideoState = {
    inProgress: false,
};

export const videoSlice = createSlice({
    initialState,
    name: 'video',
    reducers: {
        setVideoEnded: (state) => {
            state.inProgress = false;
        },
        setVideoStarting: (state) => {
            state.inProgress = true;
        },
    },
});

export const { setVideoEnded, setVideoStarting } = videoSlice.actions;

export const selectIfVideoInProgress = (state: RootState) => state.video.inProgress;

export default videoSlice.reducer;
