import apiClient from 'api/api-client';
import Evaporate, { AddConfig, CreateConfig } from 'evaporate';
import createHash from 'sha.js';
import SparkMD5 from 'spark-md5';

type ImageUploadSettingsResponse = {
    access_key_id: string;
    acl: string;
    allow_existence_optimization: boolean;
    bucket: string;
    endpoint: string;
    object_key: string;
    region: string;
    server_side_encryption: string;
    session_token: string;
};

function generateAmzCommonHeaders(sessionToken: string) {
    const headers: AddConfig['xAmzHeadersCommon'] = {};

    if (sessionToken) {
        headers['x-amz-security-token'] = sessionToken;
    }

    return headers;
}

function generateAmzInitHeaders(
    acl: ImageUploadSettingsResponse['acl'],
    serverSideEncryption: ImageUploadSettingsResponse['server_side_encryption'],
    sessionToken: ImageUploadSettingsResponse['session_token']
) {
    const headers: AddConfig['xAmzHeadersAtInitiate'] = {};

    if (acl) {
        headers['x-amz-acl'] = acl;
    }

    if (sessionToken) {
        headers['x-amz-security-token'] = sessionToken;
    }

    if (serverSideEncryption) {
        headers['x-amz-server-side-encryption'] = serverSideEncryption;
    }

    return headers;
}

const customAuthMethod: CreateConfig['customAuthMethod'] = async (
    signParams,
    signHeaders,
    stringToSign,
    signatureDateTime
) => {
    try {
        const { data } = await apiClient.post<{ s3ObjKey: string }>('/api/people/get-aws-v4-signature/', {
            datetime: signatureDateTime,
            dest: 'profile-images',
            to_sign: stringToSign,
        });
        return data.s3ObjKey;
    } catch (error: any) {
        throw error?.error;
    }
};

const computeMd5: CreateConfig['cryptoMd5Method'] = (data) => btoa(SparkMD5.ArrayBuffer.hash(data, true));
const computeSha256: CreateConfig['cryptoHexEncodedHash256'] = (data) =>
    createHash('sha256')
        .update(data as any, 'utf-8')
        .digest('hex');

async function uploadImageToS3(imageUploadSettingsResponse: ImageUploadSettingsResponse, file: File) {
    const createConfig: CreateConfig = {
        allowS3ExistenceOptimization: imageUploadSettingsResponse.allow_existence_optimization,
        awsRegion: imageUploadSettingsResponse.region,
        aws_key: imageUploadSettingsResponse.access_key_id,
        aws_url: imageUploadSettingsResponse.endpoint,
        bucket: imageUploadSettingsResponse.bucket,
        computeContentMd5: true,
        cryptoHexEncodedHash256: computeSha256,
        cryptoMd5Method: computeMd5,
        customAuthMethod,
        logging: true,
        partSize: 20 * 1024 * 1024,
        s3FileCacheHoursAgo: imageUploadSettingsResponse.allow_existence_optimization ? 12 : 0,
    };

    const addConfig: AddConfig = {
        contentType: file.type,
        file: file,
        name: imageUploadSettingsResponse.object_key,
        progress: (progressRatio) => {
            console.log(progressRatio);
        },
        warn: (message) => {
            console.log(message);
        },
        xAmzHeadersAtInitiate: generateAmzInitHeaders(
            imageUploadSettingsResponse.acl,
            imageUploadSettingsResponse.server_side_encryption,
            imageUploadSettingsResponse.session_token
        ),
        xAmzHeadersCommon: generateAmzCommonHeaders(imageUploadSettingsResponse.session_token),
    };

    const evaporate = await Evaporate.create(createConfig);
    const s3ObjKey = await evaporate.add(addConfig);

    return `${imageUploadSettingsResponse.endpoint}/${imageUploadSettingsResponse.bucket}/${s3ObjKey}`;
}

function getImageUploadSettings(fileName: string, file: File) {
    return apiClient.post<ImageUploadSettingsResponse>('/api/people/image-upload-settings/', {
        dest: 'profile-images',
        name: fileName,
        size: file.size,
        type: file.type,
    });
}

function updateImage(avatarUrl: string) {
    return apiClient.post('/api/people/update-image/', {
        avatar_url: avatarUrl,
    });
}

export async function uploadImage(fileName: string, file: File) {
    const { data: settingsData } = await getImageUploadSettings(fileName, file);
    const avatarUrl = await uploadImageToS3(settingsData, file);
    await updateImage(avatarUrl);
}
