import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectTheme } from 'ui/slice';

export function useTheme() {
    const theme = useAppSelector(selectTheme);

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [theme]);
}
