// @ts-nocheck
import { useEffect } from 'react';

const SegmentLoader = () => {
    useEffect(() => {
        const segmentKey = process.env.REACT_APP_SEGMENT;

        if (!segmentKey) {
            console.error('REACT_APP_SEGMENT is not defined');
            return;
        }

        const scriptSrc = `https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`;

        // Check if the Segment script is already added
        if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
            const analytics = (window.analytics = window.analytics || []);

            if (!analytics.initialize) {
                if (analytics.invoked) {
                    console.error('Segment snippet included twice.');
                } else {
                    analytics.invoked = true;
                    analytics.methods = [
                        'trackSubmit',
                        'trackClick',
                        'trackLink',
                        'trackForm',
                        'pageview',
                        'identify',
                        'reset',
                        'group',
                        'track',
                        'ready',
                        'alias',
                        'debug',
                        'page',
                        'screen',
                        'once',
                        'off',
                        'on',
                        'addSourceMiddleware',
                        'addIntegrationMiddleware',
                        'setAnonymousId',
                        'addDestinationMiddleware',
                        'register',
                    ];

                    analytics.factory = function (t) {
                        return function () {
                            const args = Array.prototype.slice.call(arguments);
                            args.unshift(t);
                            analytics.push(args);
                            return analytics;
                        };
                    };

                    for (let i = 0; i < analytics.methods.length; i++) {
                        const method = analytics.methods[i];
                        analytics[method] = analytics.factory(method);
                    }

                    analytics.load = function (key, options) {
                        const script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.async = true;
                        script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

                        const firstScript = document.getElementsByTagName('script')[0];
                        firstScript.parentNode.insertBefore(script, firstScript);

                        analytics._loadOptions = options;
                    };

                    analytics.SNIPPET_VERSION = '4.1.0';
                    analytics.load(segmentKey, { page: false });
                }
            } else {
                console.log('Segment script already loaded.');
            }
        }
    }, []);

    return null;
};

export default SegmentLoader;
