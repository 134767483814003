import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import commonConfig from 'common/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import SegmentLoader from 'utils/analytics/SegmentLoader';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import './utils/analytics/index';

if (!commonConfig.IS_DEV) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
    });
}

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <SegmentLoader />
            <App />
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
