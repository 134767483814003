const ENV = process.env.NODE_ENV;

export type CommonConfig = {
    DOMAIN: string;
    ENV: string;
    IS_DEV: boolean;
    IS_PRODUCTION: boolean;
    IS_TEST: boolean;
    PUSHER_APP_KEY: string;
    SOCKETS_HOST: string;
    VIDEO_ENABLED_IN_LESS: number;
};

const videoEnabledInLess = parseInt(process.env.REACT_APP_VIDEO_ENABLED_IN_LESS || '180', 10);

const commonConfig: CommonConfig = {
    DOMAIN: window.location.origin,
    ENV,
    IS_DEV: ENV === 'development',
    IS_PRODUCTION: ENV === 'production',
    IS_TEST: ENV === 'test',
    PUSHER_APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY || '',
    SOCKETS_HOST: process.env.REACT_APP_SOCKETS_HOST || `wss://${window.location.host}`,
    VIDEO_ENABLED_IN_LESS: videoEnabledInLess > -1 ? videoEnabledInLess : Infinity,
};

export default commonConfig;
