import isNil from 'utils/isNil';

class Storage {
    private storage = window.localStorage;

    public getItem<T = any>(key: string): T | null {
        const value = this.storage.getItem(key);

        if (!isNil(value)) {
            return JSON.parse(value as string);
        }

        return value as null;
    }

    public hasItem(key: string): boolean {
        return !isNil(this.getItem(key));
    }

    public removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    public setItem(key: string, value: any): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    public setItems(items: { [key: string]: any }): void {
        Object.keys(items).forEach((key) => {
            this.setItem(key, items[key]);
        });
    }
}

const storage = new Storage();

export default storage;
