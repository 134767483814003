import apiClient, { ApiResponse } from 'api/api-client';
import { uploadImage } from 'api/image';
import { AvatarColor } from 'common/avatars';
import { ID } from 'common/genericTypes';
import { RegistrationStatus } from 'common/registrationStatus';
import { Theme } from 'common/theme';

export type CheckTokenResponse = {
    email: string;
    manager: string;
};

function checkToken(token: string) {
    return apiClient.get<CheckTokenResponse>(`/api/user/token-check/${token}`);
}

export type PusherAuthRequest = {
    channel_name: string;
    meeting_id: string;
    socket_id: string;
};
export type PusherAuthResponse = any;

function pusherAuth(data: PusherAuthRequest) {
    return apiClient.post<PusherAuthResponse>('/api/user/pusher-auth', data);
}

function accountDelete() {
    return apiClient.post<void>(`/api/user/delete`);
}

// TODO Add proper types.
export type AccountInfoResponse = any;

function accountInfo() {
    return apiClient.get<AccountInfoResponse>('/api/user/account-info');
}

export type CalendarEventResponse = {
    creator?: string;
    email: string;
    gcal_id: string;
    google_id: string;
    next_events: {
        duration: number;
        end: string;
        id: string;
        start: string;
        timezone: string;
        title: string;
    }[];
    recurrence: {
        frequency: string;
        interval: number;
    };
    relationship_id: string;
    title: string;
};

function calendarEvents() {
    return apiClient.get<CalendarEventResponse[]>('/api/user/calendar');
}

export type ExchangeGoogleAuthCodeRequest = {
    activate_token: string | null;
    code: string | null;
    provider: string;
    redirect_uri: string;
    scope: string | null;
};
export type ExchangeMicrosoftAuthCodeRequest = {
    token: string | null;
};
export type ExchangeAuthCodeResponse = {
    created_at: string;
    email: string;
    id: string;
    refresh: string;
    token: string;
    updated_at: string;
};

function exchangeSocialAuthCode(
    provider: 'google',
    requestData: {
        data: ExchangeGoogleAuthCodeRequest;
        sharedLinkToken?: string | null;
    }
): Promise<ApiResponse<ExchangeAuthCodeResponse>>;
function exchangeSocialAuthCode(
    provider: 'microsoft',
    requestData: {
        data: ExchangeMicrosoftAuthCodeRequest;
        sharedLinkToken?: string | null;
    }
): Promise<ApiResponse<ExchangeAuthCodeResponse>>;
function exchangeSocialAuthCode(
    provider: 'google' | 'microsoft',
    requestData: {
        data: ExchangeGoogleAuthCodeRequest | ExchangeMicrosoftAuthCodeRequest;
        sharedLinkToken?: string | null;
    }
) {
    if (provider === 'google') {
        return apiClient.post<ExchangeAuthCodeResponse>(
            `/api/login/social/jwt-pair-user/google-oauth2${
                requestData.sharedLinkToken ? `?token=${encodeURIComponent(requestData.sharedLinkToken)}` : ''
            }`,
            requestData.data
        );
    }

    return apiClient.post<ExchangeAuthCodeResponse>(
        `/api/microsoft/exchange/token${
            requestData.sharedLinkToken ? `?token=${encodeURIComponent(requestData.sharedLinkToken)}` : ''
        }`,
        requestData.data
    );
}

export type RefreshTokenRequest = {
    refresh: string;
};
export type RefreshTokenResponse = {
    access: string;
};

function refreshToken(data: RefreshTokenRequest) {
    return apiClient.post<RefreshTokenResponse>(`/api/token/refresh/`, data);
}

export type RegisterInterestRequest = {
    email: string;
    full_name: string;
};
// TODO Add proper types.
export type RegisterInterestResponse = any;

function registerInterest(data: RegisterInterestRequest) {
    return apiClient.post<RegisterInterestResponse>('/api/people/register-interest', data);
}

export type PeopleDetailsResponse = {
    name: string;
    organisation: string;
};

export type UserInfoResponse = {
    academy_pilot: boolean;
    current_period_end?: string;
    details: {
        avatar: string;
        avatar_colour: AvatarColor | null;
        full_name: string;
        is_manager: boolean;
        is_report: boolean;
        job_title: string;
        nickname: string;
        organisation: {
            id: ID;
            is_editable: boolean;
            name: string;
        };
        pronouns: string;
        registration_status: RegistrationStatus;
        role: 'owner' | 'admin' | 'member';
        theme: Theme;
        timezone: string;
    };
    email: string;
    free_trail_expires_at?: string;
    has_calendar_permissions: boolean;
    has_google_access: boolean;
    id: ID;
    is_active: boolean;
    is_admin?: boolean;
    is_staff: boolean;
    is_subscribed?: boolean;
    is_whitelisted?: boolean;
    logged_in: boolean;
    onboarded?: boolean;
    opt_ins: ('marketing' | never)[];
    should_pay?: boolean;
    show_next_step?: boolean;
};

function getUserInfo() {
    return apiClient.get<UserInfoResponse>('/api/user/user-details');
}

export type UpdateUserInfoRequest =
    | {
          full_name: string;
          is_completed?: true;
          job_title: string;
          nickname: string;
          opt_ins?: UserInfoResponse['opt_ins'];
          organisation_name?: string;
          pronouns: string;
          timezone: string;
      }
    | {
          is_completed: true;
      }
    | {
          theme: Theme;
      }
    | {
          timezone: string;
      };
export type UpdateUserInfoResponse = UserInfoResponse;

export type ManagerOnboardingData = {
    experience: string;
    improvement_areas: string[];
    name: string;
    pronouns?: string;
    surname?: string;
    team_size: string;
};

async function updateUserInfo(data: UpdateUserInfoRequest, fileName?: string, file?: File) {
    if (fileName && file) {
        await uploadImage(fileName, file);
    }

    return apiClient.patch<UpdateUserInfoResponse>('/api/user/user-details', data);
}

async function updateManagerOnboardingData(data: ManagerOnboardingData) {
    return apiClient.post<UpdateUserInfoResponse>('/api/user/manager-oboard', data);
}

export type GmailPotentialUserResponse = {
    email: string;
    name: string;
};

function getGmailPotentialUser(gmailPotentialUserId: string) {
    return apiClient.get<GmailPotentialUserResponse>(`/api/people/gmail-potential-user/${gmailPotentialUserId}`);
}

function getEmployee(employeeId: ID) {
    return apiClient.get<UserInfoResponse>(`/api/user/user-details/${employeeId}`);
}

function stripeUnsubscribe() {
    return apiClient.post<{}>(`/api/user/unsubscribe`);
}

export type ScopeAccessResponse = {
    has_all_scopes: boolean;
    onboarded: boolean;
};

function getScopeAccess() {
    return apiClient.get<ScopeAccessResponse>('/api/people/check-scopes/');
}

function getManager() {
    return apiClient.get<UserInfoResponse>('/api/user/user-details/manager');
}

function getThinkificSsoUrl() {
    return apiClient.get<{ thinkific_sso_url: string }>('/api/user/thinkific-sso-url');
}

export type ExchangeAuthCodeErrorResponse = {
    email?: string;
    error: string;
    full_name?: string;
};

export type InviteUsersTokenResponse = {
    token: string;
};

function getInviteUsersToken() {
    return apiClient.get<InviteUsersTokenResponse>('/api/user/registration-token');
}

export {
    checkToken,
    pusherAuth,
    accountDelete,
    accountInfo,
    calendarEvents,
    exchangeSocialAuthCode,
    refreshToken,
    registerInterest,
    updateUserInfo,
    updateManagerOnboardingData,
    getUserInfo,
    getGmailPotentialUser,
    getEmployee,
    getScopeAccess,
    getManager,
    getThinkificSsoUrl,
    getInviteUsersToken,
    stripeUnsubscribe,
};
