import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from 'store';
import Theme from 'ui/Theme';
import lazyLoad from 'utils/lazyLoad';

import Timezone from './components/Timezone';

const Router = lazyLoad(() => import('routing/Router'));

function App() {
    return (
        <StrictMode>
            <Provider store={store}>
                <Theme>
                    <Router />
                    <Timezone />
                    <ToastContainer
                        autoClose={5000}
                        className="notification"
                        closeButton={false}
                        closeOnClick
                        hideProgressBar
                        icon={false}
                        limit={1}
                        newestOnTop
                        pauseOnFocusLoss
                        pauseOnHover
                        position="bottom-center"
                    />
                </Theme>
            </Provider>
        </StrictMode>
    );
}

export default App;
