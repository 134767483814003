import storage from './storage';

class Tokens {
    private readonly ACCESS_TOKEN_NAME = 'token';
    private readonly REFRESH_TOKEN_NAME = 'refresh';

    public getAccessToken(): string | null {
        return storage.getItem<string>(this.ACCESS_TOKEN_NAME);
    }

    public getRefreshToken(): string | null {
        return storage.getItem<string>(this.REFRESH_TOKEN_NAME);
    }

    public hasAccessToken(): boolean {
        return storage.hasItem(this.ACCESS_TOKEN_NAME);
    }

    public hasRefreshToken(): boolean {
        return storage.hasItem(this.REFRESH_TOKEN_NAME);
    }

    public storeAccessToken(value: string): void {
        return storage.setItem(this.ACCESS_TOKEN_NAME, value);
    }

    public storeRefreshToken(value: string): void {
        return storage.setItem(this.REFRESH_TOKEN_NAME, value);
    }

    public clearAccessToken(): void {
        return storage.removeItem(this.ACCESS_TOKEN_NAME);
    }

    public clearRefreshToken(): void {
        return storage.removeItem(this.REFRESH_TOKEN_NAME);
    }

    public deleteCookie(name: string): void {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * -1);
        const utcDate = d.toUTCString();
        const value = `${name}=;path=/;expires=${utcDate}`;
        document.cookie = value;
    }

    public clearTokens(): void {
        this.deleteCookie('logged_in');
        this.clearAccessToken();
        this.clearRefreshToken();
    }
}

const tokens = new Tokens();

export default tokens;
