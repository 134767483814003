import commonConfig from 'common/config';
import ReactGA from 'react-ga4';

import ANALYTICS_EVENTS from './events';

class Analytics {
    static instance: Analytics;

    constructor() {
        const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
        if (trackingId) {
            ReactGA.initialize(trackingId);
        }
    }

    public logEvent = (event: ANALYTICS_EVENTS, data?: Record<string, string>) => {
        if (commonConfig.ENV === 'production') {
            ReactGA.event({
                action: event,
                category: 'User',
                ...data,
            });
            // track event with fbq (facebook pixel)
            // @ts-ignore
            window.fbq('trackCustom', event, data);
        }
    };

    static getInstance(): Analytics {
        if (!Analytics.instance) {
            Analytics.instance = new Analytics();
        }
        return Analytics.instance;
    }
}

export default Analytics.getInstance();
