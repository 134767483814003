import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from 'store';
import { ViewAs } from 'user/slice';
import isNil from 'utils/isNil';
import tokens from 'utils/services/tokens';

import { RefreshTokenResponse } from './user';

const HOST = process.env.REACT_APP_API_HOST || window.location.origin;

// Function that will be called to refresh authorization
async function refreshAuthLogic(failedRequest: AxiosError): Promise<any> {
    try {
        if (!tokens.getRefreshToken()) {
            return;
        }

        const { data } = await axios.post<RefreshTokenResponse>(
            `/api/token/refresh/`,
            {
                refresh: tokens.getRefreshToken(),
            },
            {
                skipAuthRefresh: true,
            } as any
        );

        tokens.storeAccessToken(data.access);
        if (failedRequest.response?.config.headers) {
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + data.access;
        }
    } catch (error) {
        tokens.clearTokens();
        window.location.href = '/login';
    }
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

function _authHeaderFromUser(): string | undefined {
    try {
        if (tokens.hasAccessToken()) {
            return `Bearer ${tokens.getAccessToken()}`;
        }
    } catch (error) {
        // Token doesn't have a valid format.
    }
}

function _setupAuthHeader(config: AxiosRequestConfig): AxiosRequestConfig {
    const authHeader = _authHeaderFromUser();

    if (authHeader && config.headers) {
        config.headers['Authorization'] = authHeader;
    }

    return config;
}

axios.interceptors.request.use(
    (config) => {
        config = _setupAuthHeader(config);

        if (config.headers) {
            config.headers['Accept'] = 'application/json';
            config.headers['Content-Type'] = 'application/json';
        }

        const currentUrl = config.url;
        if (!currentUrl?.startsWith('http')) {
            config.url = `${HOST}${currentUrl}`;
        }

        if (!config.params) {
            config.params = {};
        }
        config.params['role'] = store.getState().user.viewAs === ViewAs.Manager ? 'manager' : 'report';

        return config;
    },
    (error) => Promise.reject(error)
);

export const isApiError = axios.isAxiosError;

export function hasErrorResponse<T>(response: AxiosResponse<T> | unknown): response is AxiosResponse<T> {
    return !isNil(response);
}

export type ApiResponse<T> = AxiosResponse<T>;

export default axios;
