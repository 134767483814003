import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import uiReducer from 'ui/slice';
import userReducer from 'user/slice';
import videoReducer from 'video/slice';

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['ui/saveTheme/fulfilled'],
            },
        }),
    reducer: {
        ui: uiReducer,
        user: userReducer,
        video: videoReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
