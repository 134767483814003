import { lazy } from 'react';

import storage from './services/storage';

export default function lazyLoad(componentImport: { (): Promise<any> }) {
    return lazy(async () => {
        const hasBeenForceRefreshed = JSON.parse(storage.getItem('force-refreshed') || 'false');

        try {
            const component = await componentImport();
            storage.setItem('force-refreshed', 'false');
            return component;
        } catch (error) {
            if (!hasBeenForceRefreshed) {
                storage.setItem('force-refreshed', 'true');
                return window.location.reload();
            }

            // The page has already been reloaded or we have no access to localStorage to do a controlled reload
            // Let the app throw the error.
            throw error;
        }
    });
}
