import { updateUserInfo } from 'api/user';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUser, updateTimezone } from 'user/slice';
import datetime from 'utils/services/datetime';

export function useTimezone() {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const timezone = datetime.tz.guess();
        if (user.id && !user.timeZone && timezone) {
            const callback = async () => {
                await updateUserInfo({
                    timezone,
                });
                dispatch(updateTimezone(timezone));
            };

            callback();
        }
    }, [dispatch, user.id, user.timeZone]);
}
