import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateUserInfo, UserInfoResponse } from 'api/user';
import { Theme } from 'common/theme';
import { RootState } from 'store';
import { getUserInfo } from 'user/slice';

export type UiState = {
    fullScreen: boolean;
    headerText: string;
    sidebarCollapsed: boolean;
    theme: Theme;
};

const initialState: UiState = {
    fullScreen: false,
    headerText: '',
    sidebarCollapsed: false,
    theme: window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
};

export const saveTheme = createAsyncThunk('ui/saveTheme', async (theme: Theme) =>
    updateUserInfo({
        theme,
    })
);

export const uiSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.fulfilled, (state, action: PayloadAction<UserInfoResponse>) => {
            state.theme = action.payload.details?.theme || 'light';
        });
        builder.addCase(saveTheme.pending, (state, action) => {
            state.theme = action.meta.arg;
        });
        builder.addCase(saveTheme.rejected, (state, action) => {
            state.theme = action.meta.arg === 'dark' ? 'light' : 'dark';
        });
    },
    initialState,
    name: 'ui',
    reducers: {
        collapseSidebar: (state, action: PayloadAction<boolean>) => {
            state.sidebarCollapsed = action.payload;
        },
        revertHeaderText: (state) => {
            state.headerText = '';
        },
        setHeaderText: (state, action: PayloadAction<string>) => {
            state.headerText = action.payload;
        },
        toggleFullScreen: (state) => {
            state.fullScreen = !state.fullScreen;
        },
        toggleSidebar: (state) => {
            state.sidebarCollapsed = !state.sidebarCollapsed;
        },
    },
});

export const { toggleFullScreen, toggleSidebar, collapseSidebar, setHeaderText, revertHeaderText } = uiSlice.actions;

export const selectTheme = (state: RootState) => state.ui.theme;
export const selectIsDarkTheme = (state: RootState) => state.ui.theme === 'dark';
export const selectSidebarCollapsed = (state: RootState) => state.ui.sidebarCollapsed;
export const selectHeaderText = (state: RootState) => state.ui.headerText;

export default uiSlice.reducer;
