import * as userEndpoints from 'api/user';
import { ViewAs } from 'user/slice';
import { generateGoogleAuthUrl } from 'utils/services/google';
import storage from 'utils/services/storage';
import tokens from 'utils/services/tokens';

const ViewAsKey = 'ViewAs';

function registerWithGoogle(token: string | null = null, email?: string): void {
    window.location.assign(generateGoogleAuthUrl('register', token, email));
}

function connectGoogleCalendar(email: string): void {
    window.location.assign(generateGoogleAuthUrl('authorize_calendar', null, email));
}

function loginWithGoogle(email?: string) {
    return registerWithGoogle(null, email);
}

async function refreshToken(): Promise<void> {
    try {
        if (!tokens.hasRefreshToken()) {
            throw new Error('Refresh token is missing.');
        }

        const { data } = await userEndpoints.refreshToken({
            refresh: tokens.getRefreshToken() as string,
        });

        tokens.storeAccessToken(data.access);
    } catch (error) {
        tokens.clearTokens();

        throw error;
    }
}

async function exchangeSocialAuthCode(
    provider: 'google',
    requestData: {
        data: userEndpoints.ExchangeGoogleAuthCodeRequest;
        sharedLinkToken?: string | null;
    }
): Promise<void>;
async function exchangeSocialAuthCode(
    provider: 'microsoft',
    requestData: {
        data: userEndpoints.ExchangeMicrosoftAuthCodeRequest;
        sharedLinkToken?: string | null;
    }
): Promise<void>;
async function exchangeSocialAuthCode(provider: any, requestData: any): Promise<void> {
    const { data } = await userEndpoints.exchangeSocialAuthCode(provider, requestData);
    tokens.storeAccessToken(data.token);
    tokens.storeRefreshToken(data.refresh);
}

function logout(): void {
    tokens.clearTokens();
    localStorage.removeItem('lastActivity');
    window.location.reload();
}

function clearTokens(): void {
    tokens.clearTokens();
}

function getUserInfo() {
    return userEndpoints.getUserInfo();
}

function saveViewAs(viewAs: ViewAs) {
    return storage.setItem(ViewAsKey, viewAs);
}

function getViewAs(): ViewAs | null {
    const viewAs = storage.getItem(ViewAsKey);
    if (viewAs === ViewAs.Report || viewAs === ViewAs.Manager) {
        return viewAs;
    }
    return null;
}

function checkInactivity() {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
        const lastActivityTime = new Date(lastActivity).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - lastActivityTime;
        const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
        if (timeDifference >= twelveHoursInMilliseconds) {
            logout();
        }
    }
}

function updateLastActivity() {
    localStorage.setItem('lastActivity', new Date().toISOString());
}

document.addEventListener('click', updateLastActivity);
setInterval(checkInactivity, 30 * 60 * 1000);

const userAPI = {
    checkInactivity,
    clearTokens,
    connectGoogleCalendar,
    exchangeSocialAuthCode,
    getUserInfo,
    getViewAs,
    loginWithGoogle,
    logout,
    refreshToken,
    registerWithGoogle,
    saveViewAs,
};

export default userAPI;
