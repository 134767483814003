import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfoResponse } from 'api/user';
import { AvatarColor } from 'common/avatars';
import { ID } from 'common/genericTypes';
import { RegistrationStatus } from 'common/registrationStatus';
import { RootState } from 'store';

import userAPI from './userAPI';

export enum ViewAs {
    Report,
    Manager,
}

export type UserState = {
    academyPilot: boolean;
    avatar: string | null;
    color: AvatarColor | null;
    displayName: string | null;
    email: string | null;
    fullName: string | null;
    hasCalendarAccess: boolean;
    hasGoogleAccess: boolean;
    id: ID | null;
    isManager: boolean;
    isReport: boolean;
    isStaff: boolean | null;
    jobTitle: string | null;
    notifications: {
        marketing: boolean;
    };
    organization: {
        id: ID;
        isEditable: boolean;
        name: string;
    } | null;
    pronouns: string | null;
    registrationStatus: RegistrationStatus | null;
    role: 'owner' | 'admin' | 'member';
    timeZone: string | null;
    viewAs: ViewAs;
};

const initialState: UserState = {
    academyPilot: false,
    avatar: null,
    color: null,
    displayName: null,
    email: null,
    fullName: null,
    hasCalendarAccess: false,
    hasGoogleAccess: false,
    id: null,
    isManager: false,
    isReport: true,
    isStaff: null,
    jobTitle: null,
    notifications: {
        marketing: false,
    },
    organization: null,
    pronouns: null,
    registrationStatus: null,
    role: 'member',
    timeZone: null,
    viewAs: ViewAs.Report,
};

export const getUserInfo = createAsyncThunk('user/getInfo', async () => {
    const { data } = await userAPI.getUserInfo();
    return data;
});

export const userSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.fulfilled, (state, action: PayloadAction<UserInfoResponse>) => {
            // TODO Add other data from response once they are needed.
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.fullName = action.payload.details?.full_name;
            state.displayName = action.payload.details?.nickname;
            state.avatar = action.payload.details?.avatar;
            state.isStaff = action.payload.is_staff;
            state.jobTitle = action.payload.details?.job_title;
            state.pronouns = action.payload.details?.pronouns;
            state.timeZone = action.payload.details?.timezone;
            state.registrationStatus = action.payload.details?.registration_status;
            state.color = action.payload.details?.avatar_colour;
            state.hasGoogleAccess = action.payload.has_google_access;
            state.hasCalendarAccess = action.payload.has_calendar_permissions;
            state.isManager = action.payload.details?.is_manager;
            state.isReport = action.payload.details?.is_report;
            state.organization = action.payload.details?.organisation
                ? {
                      id: action.payload.details.organisation.id,
                      isEditable: action.payload.details.organisation.is_editable,
                      name: action.payload.details.organisation.name,
                  }
                : null;
            state.notifications = {
                marketing: action.payload.opt_ins.includes('marketing'),
            };
            state.viewAs = state.isManager ? ViewAs.Manager : ViewAs.Report;
            state.role = action.payload.details?.role || 'member';
            state.academyPilot = action.payload.academy_pilot;
        });
    },
    initialState,
    name: 'user',
    reducers: {
        setViewAs(state, action: PayloadAction<ViewAs>) {
            if (state.isManager && state.isReport) {
                state.viewAs = action.payload;
            }
        },
        updateRegistrationStatus(state, action: PayloadAction<RegistrationStatus>) {
            state.registrationStatus = action.payload;
        },
        updateTimezone(state, action: PayloadAction<string>) {
            state.timeZone = action.payload;
        },
    },
});

export const { updateRegistrationStatus, updateTimezone, setViewAs } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectIsUserLoggedIn = createSelector(selectUser, (user) => !!user.email);
export const selectViewAsManager = (state: RootState) => state.user.viewAs === ViewAs.Manager;

export default userSlice.reducer;
