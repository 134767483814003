import React from 'react';

import { useTheme } from './hooks';

export type ThemeProps = {
    children: React.ReactNode;
};

function Theme({ children }: ThemeProps) {
    useTheme();

    return <>{children}</>;
}

export default Theme;
